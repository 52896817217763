$mincho: "Zen Old Mincho", serif;
$gothic: "Noto Sans JP", sans-serif;
$breakpointSmallPc: 1000px;
$breakpointTablet: 768px;
$breakpointSp: 480px;
$maxContentWidth: 1440px;

@mixin buttonStyle {
  padding: 8px 16px;
  background-color: rgb(var(--main-color));
  color: white;
  font-size: 16px;
  text-decoration: none;
  border-radius: 8px;

  &:hover {
    opacity: 0.6;
  }
}

:root {
  --main-color: 66,179,53;
  --sub-color-lighter: 141,214,172;
  --sub-color-darker: 1,92,84;
}

.body_inner {
  font-family: $mincho;
  font-optical-sizing: auto;
  overflow: hidden;

  /* 共通パーツ:start */
  .sp-only {
    display: none;
    @media (max-width: $breakpointSp) {
      display: block;
    }
  }

  .section {
    &.green_back {
      background-color: rgb(var(--sub-color-darker), 1);
      color: white;
    }

    .section_inner {
      padding: 64px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      @media (max-width: $breakpointTablet) {
        padding: 32px 0;
      }
    }

    .section_title {
      font-size: 32px;
      font-weight: bold;
      margin: 0;
      border-bottom: 4px solid rgb(var(--main-color), 1);
    }
  }

  .animation {
     opacity: 0;
     transform: translate3d(0, 100%, 0);
     transition: all 2s ease;
   &.left {
    transform: translate3d(-100%, 0, 0);
   }
   &.right {
    transform: translate3d(100%, 0, 0);
   }
   &.delay {
    transition: all 3s ease;
   }
   &.fast {
    transition: all 1 ease;
   }

   &.active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
   }
  }
  /* 共通パーツ:end */

  .mainvisual_section {
    background-image: url('../img/mainvisual_white.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    position: relative;

    @media (max-width: $breakpointSmallPc) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .corp_name {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      padding: 16px 24px;
      display: flex;
      gap: 12px;
      flex-direction: column;

      @media (max-width: $breakpointSmallPc) {
        padding: 12px;
        gap: 8px;
      }

      .corp_full_name {
        display: inline-flex;
        flex-direction: column;

        .corp_type_text {
          font-size: 12px;

          @media (max-width: $breakpointSmallPc) {
            font-size: 10px;
          }
        }

        .corp_name_text {
          position: relative;
          left: -6px; // font-sizeの差により若干社名が右にずれるので調整
          letter-spacing: -6px;
          font-size: 48px;
          line-height: .9;

          @media (max-width: $breakpointSmallPc) {
            font-size: 36px;
            left: -4px;
          }
        }
      }


      .logo_wrapper {
        display: flex;
        align-items: top;
        gap: 8px;

        .logo {
          width: 80px;
          height: 80px;

          @media (max-width: $breakpointSmallPc) {
            width: 56px;
            height: 56px;
          }
        }
      }

      .corp_name_text {
        font-family: $mincho;
        font-weight: bold;
      }
    }

    .copy_box {
      width: 650px;
      margin: 0 auto;
      position: absolute;
      top: calc(50% - 120px);
      left: -300px;
      right: 0;
      background-color: rgb(white, 0.4);
      padding: 32px;
      border-radius: 8px;

      @media (max-width: $breakpointSmallPc) {
        margin: 0 auto;
        width: 80%;
        position: relative;
        left: auto;
        right: auto;
        text-align: center;
        padding: 16px;
      }

      .maincopy {
        margin: 0;
        font-size: 48px;
        font-weight: bold;
        line-height: 120%;

        strong {
          color: rgb(var(--main-color));
          font-size: 56px;
          filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.8));
        }

        @media (max-width: $breakpointTablet) {
          font-size: 28px;

          strong {
            font-size: 32px;
          }
        }

        @media (max-width: 335px) {
          font-size: 24px;
        }
      }

      .subcopy {
        margin: 8px 0 0 0;
        font-size: 26px;

        strong {
          color: rgb(var(--main-color));
          font-size: 28px;
          filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.8));
        }

        @media (max-width: $breakpointTablet) {
          font-size: 12px;

          strong {
            font-size: 16px;
          }
        }
      }
    }

    .mainvisual_cta {
      position: absolute;
      width: 100%;
      bottom: calc(50% - 240px);

      .cta_button_wrapper {
        width: 300px;
        margin: 0 auto;
        display: flex;
        justify-content: center;

        .cta_button {
          @include buttonStyle;
          padding: 18px 32px;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }

  .ceo_voice_section {
    .ceo_voice_section_inner {
      .ceo_voice_box {
        display: flex;
        width: 80%;
        gap: 64px;
        margin-top: 64px;
        // align-items: flex-start;
        justify-content: center;

        @media (max-width: $breakpointTablet) {
          flex-direction: column;
          gap: 32px;
          margin-top: 32px;
          width: 100%;
        }

        /*
        .ceo_voice_img_wrapper {
          width: 50%;
          @media (max-width: $breakpointTablet) {
            width: 100%;
            padding: 0 24px;
          }

          .ceo_voice_img {
            border-radius: 8px;
            width: 100%;
          }
        }
        */

        .ceo_voice_text {
          font-size: 18px;
          width: 50%;
          margin: 0;
          line-height: 140%;
          white-space: pre-wrap;

          @media (max-width: $breakpointTablet) {
            width: 100%;
            padding: 0 24px;
            font-size: 16px;
          }

          .ceo_sign {
            display: block;
            font-size: 32px;
            font-weight: bold;
            text-align: right;

            @media (max-width: $breakpointTablet) {
              font-size: 28px;
              line-height: 1.2;
            }
          }
        }
      }
    }
  }

  .company_summary_section {
    .company_summary_section_inner {
      .company_summary_table {
        margin-top: 64px;
        width: 600px;

        @media (max-width: $breakpointTablet) {
          width: calc(100% - 48px);
          margin: 32px auto;
        }

        tr {
          border-bottom: 1px solid white;
        }

        th,td {
          padding: 16px;
        }
      }
    }
  }

  .contact_section {
    .contact_section_inner {
      .contact_box_wrapper {
        margin-top: 64px;

        @media (max-width: $breakpointTablet) {
          margin-top: 32px;
        }
        .contact_box {
          margin: 0 auto;
          display: flex;
          width: 80%;
          gap: 64px;
          align-items: flex-start;

          @media (max-width: $breakpointTablet) {
            flex-direction: column;
            gap: 32px;
            width: 100%;
            padding: 0 24px;
          }

          .contact_information {
            width: 50%;
            padding: 16px 24px;
            text-align: center;
            border: 8px solid rgb(var(--main-color));
            min-height: 360px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: $breakpointTablet) {
              width: 100%;
              border: 4px solid rgb(var(--main-color));
              padding: 16px 8px;
            }

            .contact_title {
              margin: 0 auto;
              font-size: 24px;
              width: fit-content;
              text-align: center;

              &:not(:first-child) {
                margin-top: 32px;

                @media (max-width: $breakpointTablet) {
                  margin-top: 16px;
                }
              }
            }

            .contact_btn_wrapper {
              margin-top: 16px;
              @media (max-width: $breakpointTablet) {
                margin-top: 8px;
              }

              .contact_btn {
                @include buttonStyle;
                min-width: 240px;
                border-radius: 8px;
                display: inline-block;
              }
            }

            .contact_tel_subinfo {
              display: inline-block;
              margin-top: 8px;
              font-size: 14px;
            }
          }

          .contact_img_wrapper {
            width: 50%;
            @media (max-width: $breakpointTablet) {
              width: 100%;
            }

            .contact_img {
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }

  .map_section {
    .map_section_inner {
      padding: 0;
      width: 100%;
      height: 450px;
      overflow: hidden;

      iframe {
        height: 100%;
      }
    }
  }

  .footer_section {
    .footer_section_inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0;
      width: 100%;
      
      .footer_text {
        font-size: 14px;
        margin: 0;

        @media (max-width: $breakpointTablet) {
          font-size: 12px;
        }
      }
    }
  }
}
